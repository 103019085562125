// REACT
import React from "react"
// MUI
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageWithCaption } from "../components/imageWithCaption"

// STYLES
const useStyles = makeStyles({
  img: {
    display: "block",
    overflow: "hidden",
    width: "100%",
    marginRight: "0.4rem",
  },
})

// COMPONENT FUNCTION
const PumpoutBoatsInUsePage = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      townOfBourne: file(relativePath: { eq: "town-of-bourne.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      crystalRiver: file(relativePath: { eq: "crystal-river.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      dartmouth: file(relativePath: { eq: "dartmouth.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      duxbury: file(relativePath: { eq: "duxbury.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      fairhaven: file(relativePath: { eq: "fairhaven.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      goingCoastal: file(relativePath: { eq: "going-coastal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rockportPumpoutBoat: file(
        relativePath: { eq: "rockport-pumpout-boat.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      salem: file(relativePath: { eq: "salem.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      seasidePark: file(relativePath: { eq: "seaside-park.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      wellfleet: file(relativePath: { eq: "wellfleet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const inUseBoats = [
    [
      "Barnstable, MA – HARBORMASTER",
      "https://tobweb.town.barnstable.ma.us/Departments/HarborMaster/pageview.asp?file=Office_Resources%5CPumpout-Program.html&title=Pumpout%20Program&exp=Office_Resources",
      null,
    ],
    ["Beverly, MA – HARBORMASTER", null, null],
    [
      "Bourne, MA – HARBORMASTER",
      "https://www.townofbourne.com/natural-resources/pages/pumpout-facilities-shoreside-boats",
      "townOfBourne",
    ],
    ["Brookhaven, NY – HARBORMASTER", null, null],
    ["Cape Ann Marina, MA – BOAT YARD SERVICE", null, null],
    ["City Marina Co., SC – BOAT YARD SERVICE", null, null],
    ["Coastal Environmental Services, Inc., CT – SERVICE BOAT", null, null],
    ["Cohasset, MA – HARBORMASTER", null, null],
    ["Connecticut DEP, CT – SERVICE BOAT", null, null],
    ["Constitution Marina, MA – MARINA SERVICE BOAT", null, null],
    [
      "Crystal River, FL – PUMPOUT BOAT",
      "http://www.crystalriverfl.org/page/parks_recreation",
      "crystalRiver",
    ],
    [
      "Dartmouth, MA – HARBORMASTER",
      "https://www.dartmouthharbormaster.com/services/pumpouts.php",
      "dartmouth",
    ],
    ["Duxbury, MA – HARBORMASTER", null, "duxbury"],
    ["Edgartown, MA – HARBORMASTER", null, null],
    ["Fairhaven, MA – HARBORMASTER", null, "fairhaven"],
    ["Falmouth, ME – HARBORMASTER", null, null],
    ["Friends of Casco Bay, ME – SERVICE BOAT", null, null],
    ["Going Coastal, Inc., NY - SERVICE BOAT", null, "goingCoastal"],
    ["Harwich, MA – HARBORMASTER", null, null],
    ["Hingham, MA – HARBORMASTER", null, null],
    ["Hull, MA – HARBORMASTER", null, null],
    ["Huntington, NY – HARBORMASTER", null, null],
    ["Manchester Marine, MA – BOAT YARD SERVICE", null, null],
    ["Marblehead, MA – HARBORMASTER", null, null],
    ["Marion, MA – HARBORMASTER", null, null],
    ["Mashpee, MA – HARBORMASTER", null, null],
    ["McDougall's Marine Service, MA – BOAT YARD SERVICE", null, null],
    ["Newburyport, MA – SERVICE BOAT", null, null],
    [
      "Oak Bluffs, MA – HARBORMASTER",
      "http://www.oakbluffsmarina.com/services-OBMarina.shtml",
      null,
    ],
    ["Onset Bay Marina, MA – MARINA SERVICE BOAT", null, null],
    ["Orleans, MA – HARBORMASTER", null, null],
    [
      "Provincetown, MA – HARBORMASTER",
      "https://www.provincetown-ma.gov/79/Harbor-Pier",
      null,
    ],
    [
      "Quincy, MA – HARBORMASTER",
      "http://www.harbormasters.org/rockport/index1.shtml",
      null,
    ],
    ["Quissett Harbor Boatyard, MA – SERVICE BOAT", null, null],
    ["Rocky Hill, CT - SERVICE BOAT", null, null],
    [
      "Rockport, MA – HARBORMASTER",
      "http://www.harbormasters.org/rockport/index1.shtml",
      "rockportPumpoutBoat",
    ],
    ["Rowley, MA – HARBORMASTER", null, null],
    [
      "Salem, MA – HARBORMASTER",
      "https://www.salem.com/harbormaster/pages/pump-out-stations",
      "salem",
    ],
    ["Seaside Park, NJ – HARBORMASTER", null, "seasidePark"],
    ["Ship Shape Services, Inc., TX - BOAT YARD SERVICE", null, null],
    ["Shipyard Quarters Marina, MA – BOAT YARD SERVICE", null, null],
    [
      "Wareham, MA – HARBORMASTER",
      "https://www.wareham.ma.us/dept-natural-resources-harbormaster-and-shellfish-division",
      null,
    ],
    ["Waterline Morring & Diving, MA – DIVE BOAT", null, null],
    [
      "Wellfleet, MA – HARBORMASTER",
      "http://www.wellfleet-ma.gov/harbormaster-marina",
      "wellfleet",
    ],
    ["West Newbury, MA – POLICE BOAT", null, null],
    ["Westport, MA – HARBORMASTER", null, null],
    ["Yarmouth, MA – HARBORMASTER", null, null],
  ]

  // COMPONENT
  return (
    <Layout title="Pumpout Boats In Use">
      <PageTitle title="PUMPOUT BOATS IN USE" />
      <ImageWithCaption
        fluid={data.rockportPumpoutBoat.childImageSharp.fluid}
        caption="Rockport, MA pumpout boat in the water"
        displayCaption
        maxWidth={800}
      />
      <List aria-label="List of Pumpout Boats in Use">
        {inUseBoats.map(boat => (
          <ListItem
            key={boat[0]}
            component="a"
            href={boat[2] ? data[boat[2]].childImageSharp.fluid.src : null}
          >
            <ListItemIcon>
              {boat[2] ? (
                <Img
                  fluid={data[boat[2]].childImageSharp.fluid}
                  alt={boat}
                  className={classes.img}
                />
              ) : null}
            </ListItemIcon>
            {boat[1] ? (
              <Link href={boat[1]} target="_blank" rel="noopener">
                <ListItemText primary={boat[0]} />
              </Link>
            ) : (
              <ListItemText primary={boat[0]} />
            )}
          </ListItem>
        ))}
      </List>
    </Layout>
  )
}

export default PumpoutBoatsInUsePage
